.recharts-cartesian-grid-horizontal line {
  stroke-dasharray: 2, 2;
  stroke: #D0D0DA;
}
.recharts-cartesian-axis-tick-value tspan {
  fill: #A1A1B5;
  letter-spacing: 0.03em;
  font-size: 14px;
}
.recharts-tooltip-cursor {
  fill: rgba(204, 204, 204, 0.2);
}
